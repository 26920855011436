import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import loadable from "@loadable/component";
import LazyLoadSection from "../../components/LazyLoadSection";
import StaticSection from "../../components/sections/BrandsIndexStatic";

const DeferSection = loadable(() =>
  import("../../components/sections/BrandsIndexDefer")
);

const IndexPage = () => (
  <Layout>
    <Seo
      title="Marketplace & Ad Platform for Brands"
      description="StackCommerce helps brands sell more and target users with our solutions: marketplace listings, editorial exposure, and more."
    />

    <LazyLoadSection static={<StaticSection />}>
      <DeferSection />
    </LazyLoadSection>
  </Layout>
);

export default IndexPage;
