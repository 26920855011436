import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import HomeLanding from "../HeroLanding";
import OutlinedText from "../styles/OutlinedText";
import { Headline80 } from "../styles/Headings";
import BlackHeroWithRightLines from "../BlackHeroWithRightLines";
import { black1, white } from "../styles/Colors";
import TextHighlight from "../styles/TextHighlight";
import { SlideInRight } from "../styles/animations";
import LandingGridAnimation from "../LandingGridAnimation";

const BrandsIndexStatic = () => {
  const images = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "49-brands-landing-1.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      two: file(relativePath: { eq: "49-brands-landing-2.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      three: file(relativePath: { eq: "49-brands-landing-3.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      four: file(relativePath: { eq: "49-brands-landing-4.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <HomeLanding
        link={{
          label: "Sign Up",
          attrs: { to: "/contact/?smopt=brand", state: { from: "brand" }, as: Link }
        }}
        theme={{
          animationPre: "opacity: 0;transform: translateY(60%);",
          keyFrames: SlideInRight,
          imageRatio: "68.78%",
          bottomSize: "0px",
          imageTranslate:
            "translate(calc(100% * 3.6 / 12), calc(100% * 2 / 12))",
          gatsbyWidth: "900px"
        }}
        animationComponent={<LandingGridAnimation Images={images} />}
      >
        Product discovery <br />
        <OutlinedText fill="transparent" component={Headline80} as="span">
          At Scale
        </OutlinedText>
      </HomeLanding>

      <BlackHeroWithRightLines
        padding="80px 0 160px"
        lineColor={white}
        title="EXPERTISE"
        titleElement="h2"
      >
        <p>
          Tap into our network of{" "}
          <TextHighlight theme={{ color: white, background: black1 }}>
            1k+ premium
          </TextHighlight>{" "}
          publishers to tell your brand’s story to hundreds of millions of
          future customers.
        </p>
        <p>
          Whether you're an emerging brand, an established company, or somewhere
          in between, our{" "}
          <TextHighlight theme={{ color: white, background: black1 }}>
            marketplace platform
          </TextHighlight>{" "}
          helps you drive unparalleled sales and brand awareness.
        </p>
      </BlackHeroWithRightLines>
    </>
  );
};

export default BrandsIndexStatic;
